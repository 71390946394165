import React, { useState, useRef, useEffect, useCallback } from "react";
import "./sidebar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { MenuItem, OpenHoursInteface } from "./dummyData";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import { isSideBar, setIsOpen } from "../../Redux/Reducers/searchReducer";
// import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import jwtDecode from "jwt-decode";
import { FaFontAwesome } from "react-icons/fa";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "@emotion/styled";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

interface DayTiming {
  day: string;
  opening: string;
  closing: string;
}

const StyledBackdrop = styled(Backdrop)({
  pointerEvents: "none",
});
// weekly timings
// https://server.etcnor.online/customer/store/6

const Sidebar = (position: any) => {
  const mapStyles = {
    height: "300px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 60.1877118, // Default to New York City latitude
    lng: 11.9997441, // Default to New York City longitude
  };

  const mapOptions = {
    center: { lat: 37.7749, lng: -122.4194 },
    zoom: 8,
  };

  //   const [daysTiming, setDaysTiming] = useState<DayTiming[]>([])

  //   const fetchData = async () => {
  //     try {
  //         const response = await axios.get('https://server.bamboo.rizq.no/customer/stores');
  //         const stores = response.data.successResponse;
  //         const lastStore = stores[stores.length - 1]; // Get the last store
  //         if (lastStore.isDays === 1) {
  //             setDaysTiming(lastStore.daysTiming);
  //         } else {
  //             // Handle if the last store doesn't have daysTiming
  //             console.log("Last store doesn't have daysTiming.");
  //         }
  //     } catch (error) {
  //         // Handle error
  //         console.error('Error fetching data:', error);
  //     }
  // };

  const OpenHourData: OpenHoursInteface[] = [
    {
      title: "Monday",
      time: "09:00 - 20:00",
    },
    {
      title: "Tuesday",
      time: "09:00 - 20:00",
    },
    {
      title: "Wednesday",
      time: "09:00 - 20:00",
    },
    {
      title: "Thrusday",
      time: "09:00 - 20:00",
    },
    {
      title: "Friday",
      time: "09:00 - 20:00",
    },
    {
      title: "Saturday",
      time: "09:00 - 20:00",
    },
    {
      title: "Sunday",
      time: "09:00 - 20:00",
    },
  ];

  const menuItemData: MenuItem[] = [
    {
      id: 1,
      icon: "bi bi-journal",
      title: "Terms & Condition",
      link: "/conditions",
      linkTitle: "Show",
    },
    {
      id: 2,
      icon: "bi bi-journal",
      title: "Privacy Policy",
      link: "/PrivacyPolicy",
      linkTitle: "Show",
    },
  ];

  const [hourModal, setHourModal] = useState(false);
  // const [sessionStorage.getItem('token'), setUser] = useState(false);
  const [conactModal, setContactModal] = useState(false);
  const [cookiesModal, setCookiesModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [logoutModal, setlogoutModal] = useState(false);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          alert("You clicked outside of me!");
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[currentDate.getDay()];

  const CloseLogout = () => {
    setlogoutModal(false);
  };

  const handleLogout = () => {
    CloseLogout();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("guestphone");
    localStorage.removeItem("orderId");
    window.location.href = "/";
  };
  const OpenLogoutModal = () => {
    setlogoutModal(true);
  };

  const handleLanguageChange = (event: any) => {
    setSelectedLanguage(event.target.value);
    event.stopPropagation();
  };

  const LangOpen = (event: any) => {
    setLanguageModal(true);
    event.stopPropagation();
    dispatch(setIsOpen(true));
  };
  const LangClose = (event: any) => {
    setLanguageModal(false);
    event.stopPropagation();
    dispatch(setIsOpen(true));
  };

  const handleSave = (event: any) => {
    setLanguageModal(false);
    event.stopPropagation();
  };

  const handleReset = (event: any) => {
    setLanguageModal(false);
    setSelectedLanguage("English");
    event.stopPropagation();
  };

  const CookiesOpen = () => {
    setCookiesModal(true);
  };

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

    if (!hasVisitedBefore) {
      setCookiesModal(true);
    }
  }, []);

  const CookiesClose = () => {
    localStorage.setItem("hasVisitedBefore", "true");
    setCookiesModal(false);
  };

  const handleOverlayClick = (event: any) => {
    // Prevent closing the modal if clicked outside
    event.stopPropagation();
  };

  const conatactClose = () => setContactModal(false);
  const contactOpen = () => {
    setContactModal(true);
  };

  const HourOpen = () => {
    setHourModal(true);
  };

  const HourClose = () => setHourModal(false);

  const sideBar = useSelector((state: RootState) => state.search.SideBar);
  const isOpen = useSelector((state: RootState) => state.search.isOpen);

  const dispatch = useDispatch();

  const handleSidebar = () => {
    dispatch(isSideBar(true));
  };

  const handleClose = () => {
    dispatch(isSideBar(false));
  };

  const handleCloseSidebar = (e: any) => {
    dispatch(isSideBar(false));
    console.log("click outside", e.target);
  };

  let user: any;
  let token: any = sessionStorage.getItem("token");
  if (token) {
    user = jwtDecode(token);
  }

  const [weeklytiming, setWeeklytiming] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://server.qa2.rizq.no/customer/stores"
      );
      const data = response.data.successResponse;
      console.log("here is data", data);

      // Check if data is available and has the daysTiming array
      if (data.length > 0 && data[0].hasOwnProperty("daysTiming")) {
        // Extract the daysTiming array
        const daysTimingArray = data[0].daysTiming;

        setWeeklytiming(daysTimingArray);
      } else {
        console.error("Days timing data not found in the response.");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    console.log("hre is update", weeklytiming);
  }, []);

  const maxLength = 20;

  return (
    <div className="container-sidebar">
      {/* <div className="icon-container" onClick={handleSidebar}>
        <MenuIcon className="icon-menu" style={{ fontSize: "25px" }} />
      </div> */}
      {sideBar && (
        <div className={`sideBar-container ${position}`}>
          <div className="top_bar">
            {sessionStorage.getItem("token") ? (
              <div className="d-flex align-items-center">
                <div className="profile_container">
                  <i
                    className="fa-solid fa-user profile_icons"
                    style={{ color: "white", fontSize: "20px" }}
                  ></i>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "8px" }}
                >
                  <span className="user_name">
                    {user.customer.first_name + user.customer.last_name}
                  </span>
                  <div>
                    <span className="user_email">
                      {user.customer.email_address.length > maxLength
                        ? user.customer.email_address.substring(
                            0,
                            maxLength - 8
                          ) +
                          "..." +
                          user.customer.email_address.substring(
                            user.customer.email_address.indexOf("@")
                          )
                        : user.customer.email_address}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <a href="/signup" className="sign-btn">
                  Sign Up
                </a>
                <a href="/login" className="signIn-btn">
                  Login
                </a>
              </div>
            )}
            <div
              onClick={handleClose}
              className="d-flex justify-content-center align-items-center"
            >
              <i className="bi bi-arrow-left"></i>
              <button className="close-sidebar">Close</button>
            </div>
          </div>
          <hr className="text-dark my-0 py-0 mb-2" />
          <div className="bottom-content d-flex flex-column">
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className={`bi bi-person-fill ${
                    sessionStorage.getItem("token")
                      ? "content-left-active"
                      : "content_left_text"
                  }`}
                  style={{
                    marginRight: "5px",
                    fontSize: "18px",
                    color: "#364D49",
                  }}
                ></i>
                <span
                  className={`${
                    sessionStorage.getItem("token")
                      ? "content-left-active"
                      : "content_left_text"
                  }`}
                >
                  Profile
                </span>
              </div>
              <a
                href="/profile"
                className={` ${
                  sessionStorage.getItem("token")
                    ? "content_right_active"
                    : "disabled content_text_right"
                }`}
              >
                Edit
              </a>
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className={`bi bi-file-earmark-ruled-fill  ${
                    sessionStorage.getItem("token")
                      ? "content-left-active"
                      : "content_left_text"
                  }`}
                  style={{ marginRight: "5px", color: "#364D49" }}
                ></i>
                <span
                  className={` ${
                    sessionStorage.getItem("token")
                      ? "content-left-active"
                      : "content_left_text"
                  }`}
                >
                  My Orders
                </span>
              </div>
              <a
                href="/order"
                className={` ${
                  sessionStorage.getItem("token")
                    ? "content_right_active"
                    : "disabled content_text_right"
                }`}
              >
                Show
              </a>
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="fa-solid fa-location-dot"
                  style={{
                    marginRight: "7px",
                    fontSize: "16px",
                    color: "#364D49",
                  }}
                ></i>

                <span className="content-left-active">
                  Glommengata 69, 2211 Kongsvinger, Innlandet
                </span>
              </div>
              <a
                href="/profile"
                className="content-right-disable"
                style={{ color: "white" }}
              >
                Change
              </a>
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="fa-solid fa-clock"
                  style={{
                    marginRight: "5px",
                    fontSize: "16px",
                    color: "#364D49",
                  }}
                ></i>
                <span className="content-left-active">Opening Hours</span>
              </div>
              <span onClick={HourOpen} className="content_right_active">
                Show
              </span>
            </div>
          </div>

          <hr className="py-0 mt-2 mt-md-4 mb-1 mb-md-2" />
          <div className="bottom_content">
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-globe content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>

                <span className="content-left-active">{selectedLanguage}</span>
              </div>
              <span onClick={LangOpen} className="content_right_active">
                Change
              </span>
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-person-lines-fill content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>
                <span className="content-left-active">Contact</span>
              </div>
              <span onClick={contactOpen} className="content_right_active">
                Show
              </span>
            </div>
            {menuItemData.map((items) => (
              <div className="content_wrapper_link d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <i
                    className={`${items.icon} content-left-active `}
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span className="content-left-active">{items.title}</span>
                </div>
                <a href={items.link} className="content_right_active">
                  {items.linkTitle}
                </a>
              </div>
            ))}
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-cookie content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>
                <span className="content-left-active">Cookies</span>
              </div>
              <span onClick={CookiesOpen} className="content_right_active">
                Show
              </span>
            </div>
          </div>
          {sessionStorage.getItem("token") && (
            <>
              <hr className="py-0 mt-2 mt-md-4 mb-1 mb-md-2" />
              <div className="content_wrapper_link d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center"
                  onClick={OpenLogoutModal}
                >
                  <i
                    className="fa-solid fa-arrow-right-from-bracket"
                    style={{ marginRight: "5px", color: "#364D49" }}
                    onClick={OpenLogoutModal}
                  ></i>
                  <span className="logout_text cursor-pointer">Logout</span>
                </div>
                {/* <span
                  className="content_right_active"
                  onClick={OpenLogoutModal}
                >
                  Change
                </span> */}
              </div>
            </>
          )}

          <div className="d-flex flex-md-row flex-column justify-content-between align-items-start ml-4 mt-1 d-md-none mx-3">
            <span className="app-header">
              Download Our Application for Benefits
            </span>
            <div className="d-flex justify-content-between mt-2">
              <a href="https://apps.apple.com/no/app/palace-cafe/id6471288539">
                <img
                  className="special-sidebar-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&pcampaignid=web_share"
                className="ml-3"
              >
                <img
                  className="special-sidebar-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </a>
            </div>
          </div>

          <div className="d-flex footer_sidebar">
            <span>Powered By </span>
            <img
              src={process.env.PUBLIC_URL + "/footer_side_logo.png"}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
      )}

      <Modal
        open={hourModal}
        onClose={HourClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [357, 560, 560],
            border: "none",
            borderRadius: "10px",
          }}
        >
          <div>
            <h3 className="hour-title">Opening Hours</h3>
            <div className="close-btn-modal-open-hours" onClick={HourClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div>
          </div>
          <hr className="hr-line-modal-contact" />
          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              {weeklytiming &&
                weeklytiming.map((item: any) => (
                  <div className="d-flex flex-row justify-content-between custom-hours-padding">
                    <span
                      className={` ${
                        currentDay === item.day
                          ? "hours-text-active-day"
                          : "hour-text-days"
                      }`}
                    >
                      {item.day}
                    </span>
                    <span
                      className={`${
                        currentDay === item.day
                          ? "hours-text-active-hour"
                          : "hours-text-time"
                      }`}
                    >
                      {item.opening} - {item.closing}
                    </span>
                  </div>
                ))}
              <hr className="hr-line-modal-contact-down" />
              <h3 className="hour-title pt-5 pb-0">Contact Information</h3>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Number</span>
                <span className="hours-text-time">+47 414 22 274</span>
              </div>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Email</span>
                <span className="hours-text-time">
                  bestilling@palacecafe.no
                </span>
              </div>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Address</span>
                <span className="hours-text-time address_mobile">
                  Glommengata 69, 2211 Kongsvinger
                </span>
              </div>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={conactModal}
        onClose={conatactClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [357, 560, 560],
            border: "none",
            borderRadius: "10px",
          }}
        >
          <div>
            <h3 className="hour-title">Contact Information</h3>
            <div className="close-btn-modal-open-hours" onClick={conatactClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div>
          </div>
          <hr className="hr-line-modal-contact" />
          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <div className="d-flex flex-column mt-2">
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Number</span>
                  <span className="hours-text-time">+47 414 22 274</span>
                </div>
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Email</span>
                  <span className="hours-text-time">
                    bestilling@palacecafe.no
                  </span>
                </div>
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Address</span>
                  <span className="hours-text-time address_mobile">
                    Glommengata 69, 2211 Kongsvinger
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 pt-2">
              <LoadScript googleMapsApiKey="AIzaSyBxx5-tQep4CHB2q2sppdQLl13eervY5Og">
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={18}
                  center={defaultCenter}
                >
                  {/* Your map components go here */}
                </GoogleMap>
              </LoadScript>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={cookiesModal}
        onClose={CookiesClose}
        BackdropComponent={StyledBackdrop}
        onBackdropClick={handleOverlayClick}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [312, 400, 400],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
          }}
        >
          <div>
            <h3 className="hour-title">Cookies</h3>
            {/* <div className="close-btn-modal" onClick={CookiesClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div> */}
          </div>

          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <p className="cookeies-text mb-2">
                By using our website, you consent to the use of cookies, small
                files that enhance your experience on the site.
              </p>
              <button className="cookeies-button" onClick={CookiesClose}>
                I understand{" "}
              </button>
            </div>
          </p>
        </Box>
      </Modal>
      <Modal
        open={logoutModal}
        onClose={CloseLogout}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 420, 420],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            height: "200px",
          }}
        >
          <div>
            {/* <div className="close-btn-modal" onClick={CloseLogout}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div> */}
          </div>

          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <p className="logout-text mb-2 mt-3">
                Are you sure you want to <br /> logout ?
              </p>
              <div className="d-flex justify-content-between buttonlogoutgap mt-1">
                <button className="lang-reset" onClick={CloseLogout}>
                  No{" "}
                </button>
                <button className="lang-save" onClick={handleLogout}>
                  Yes{" "}
                </button>
              </div>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={languageModal}
        onClose={LangClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 500, 500],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            margin: "0px",
          }}
        >
          <div
            style={{ marginLeft: "24px", paddingBottom: "10px" }}
            className="ml-custom"
          >
            <div style={{ margin: "0px" }}>
              <h3 className="lang-heading">Language</h3>
              <div className="close-btn-modal" onClick={LangClose}>
                {" "}
                <i className="bi bi-x-lg font-weight-bold"></i>
              </div>
            </div>
            <hr className="hr-line-modal" />

            <div className="d-flex flex-column pt-4 ">
              <div className="d-flex flex-column">
                <p className="lang-subheading">Select One</p>
                <div className="d-flex flex-column">
                  <label className="d-flex align-items-center button_gap lang-text">
                    <input
                      type="radio"
                      name="language"
                      value="English"
                      // checked={selectedLanguage === "English"}
                      onChange={handleLanguageChange}
                    />
                    English
                  </label>
                  <label className="d-flex align-items-center button_gap lang-text">
                    <input
                      type="radio"
                      name="language"
                      value="Norwegian"
                      // checked={selectedLanguage === "Norwegian"}
                      onChange={handleLanguageChange}
                    />
                    Norwegian
                  </label>
                </div>
              </div>
              <div className="d-flex mt-2 button_gap justify-content-between pt-3">
                <button className="lang-reset-specail" onClick={handleReset}>
                  Reset
                </button>
                <button className="lang-save-special" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Sidebar;
